import * as R from 'ramda';

export const currencyFormatter = (value, digits = 0, currency = 'EUR') =>
  new Intl.NumberFormat('en-IE', {
    style: 'currency',
    currency,
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(value);

export const capitalizeFirstLetter = (string = '') =>
  string.charAt(0).toUpperCase() + string.slice(1);

/**
 * Formats a number with K/M/B suffix and custom decimal places based on range
 * @param value - The number to format
 * @param decimals - Number of decimal places for M/B values (default: 2)
 * @returns Formatted string representation of the number
 */
export const abbreviateNumber = (value: number, decimals = 2): string => {
  function formatNumber(
    n: number,
    divisor: number,
    suffix: string,
    decimalPlaces: number
  ): string {
    const num = n / divisor;

    // Show decimals only if the number is below 100; otherwise, no decimals
    if (num >= 100) {
      return `${Math.floor(num)}${suffix}`;
    } else {
      return `${num.toFixed(decimalPlaces).replace(/\.?0+$/, '')}${suffix}`;
    }
  }

  // For numbers under 1000, return whole number without decimals
  if (value < 1000) {
    return Math.floor(value).toString();
  } else if (value >= 1000 && value < 1000000) {
    // Use 1 decimal place for K range
    return formatNumber(value, 1000, 'K', 1);
  } else if (value >= 1000000 && value < 1000000000) {
    return formatNumber(value, 1000000, 'M', decimals);
  } else if (value >= 1000000000) {
    return formatNumber(value, 1000000000, 'B', decimals);
  }

  // Fallback for negative numbers or other edge cases
  return Math.floor(value).toString();
};

/**
 * Generates a cryptographically secure random string of specified length
 * @param length - The desired length of the random string
 * @returns A random string containing alphanumeric characters
 */
export function generateRandomString(length: number): string {
  const charset =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const array = new Uint8Array(length);
  crypto.getRandomValues(array);

  return Array.from(array)
    .map((x) => charset.charAt(x % charset.length))
    .join('');
}

import { useState } from 'react';
import {
  Space,
  Form,
  Modal,
  Typography,
  Button,
  Row,
  message,
  Grid,
} from 'antd';
import { useSession } from 'next-auth/react';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import posthog from 'posthog-js';

import { UserProps } from 'types/user';

import { UPDATE_ME_QUERY } from 'queries';

import QuarterlyInvestmentForm from 'components/Auth/Onboarding/Investor/QuarterlyInvestment';
import { updateUser } from 'components/Profile/Edit/updateCache';

import { getPaymentStatus, PaymentStatus } from 'utils/oppHelpers';

export interface UpdateQuarterlySubscriptionProps {
  userData: UserProps;
  isOpen: boolean;
  setIsOpen(isOpen: boolean): void;
}

const UpdateQuarterlySubscription = ({
  userData,
  isOpen,
  setIsOpen,
}: UpdateQuarterlySubscriptionProps) => {
  const screens = Grid.useBreakpoint();
  const { data: session } = useSession();
  const [form] = Form.useForm();

  const [formLoading, setFormLoading] = useState(false);
  const [pauseLoading, setPauseLoading] = useState(false);

  const [saveUser] = useMutation(UPDATE_ME_QUERY, {
    update: async (cache, { data }) => {
      updateUser(cache, data, session.user.id);
    },
    onCompleted: async () => {
      setIsOpen(false);
      setFormLoading(false);
      setPauseLoading(false);
      message.success('Quarterly Investment updated');
    },
    onError: (error) => {
      setFormLoading(false);
      setPauseLoading(false);
      message.error('There was an error updating your quarterly investment');
      Sentry.captureException(error, {
        user: { id: userData?.id },
        tags: {
          action: 'dashboard_quarterly_investment_update_error',
        },
      });
    },
  });

  const onFormFinish = async (fields) => {
    if (
      fields.investorDetails.quarterlySubscription ===
        userData.investorDetails.quarterlySubscription &&
      fields.investorDetails.netWorth === userData.investorDetails.netWorth
    ) {
      setFormLoading(false);
      setPauseLoading(false);
      message.success('Quarterly Investment unchanged');
      posthog.capture('dashboard_quarterly_investment_updated');
      setIsOpen(false);
      return;
    }

    setFormLoading(true);

    try {
      await form.validateFields();

      saveUser({
        variables: {
          investorDetails: {
            id: userData.investorDetails.id,
            quarterlySubscription: fields.investorDetails.quarterlySubscription,
            netWorth: fields.investorDetails.netWorth,
          },
        },
      });
    } catch (error) {
      Sentry.captureException(error, {
        user: { id: userData?.id },
        tags: {
          action: 'dashboard_quarterly_investment_update_error',
        },
      });
    }
  };

  const quarterlySubscription = userData?.investorDetails.quarterlySubscription;
  const feesPaid =
    getPaymentStatus(userData?.OPP?.annualFee) === PaymentStatus.Active;

  const isMobile = screens.md === false;

  return (
    <Modal
      open={isOpen}
      footer={null}
      width={700}
      title={
        <Typography.Title level={isMobile ? 4 : 3} style={{ marginBottom: 0 }}>
          {!!userData?.investorDetails.quarterlySubscription
            ? 'Update'
            : 'Create'}{' '}
          your quarterly investment
        </Typography.Title>
      }
      onCancel={() => {
        setIsOpen(false);
      }}
      styles={{ header: { paddingBottom: isMobile ? 0 : 30 } }}
    >
      <Form form={form} onFinish={onFormFinish} layout="vertical">
        <QuarterlyInvestmentForm form={form} />
        <Row
          justify={quarterlySubscription && feesPaid ? 'space-between' : 'end'}
          style={{
            marginTop: 30,
            flexDirection: isMobile ? 'column-reverse' : 'row',
          }}
        >
          {quarterlySubscription && feesPaid && (
            <Button
              loading={pauseLoading}
              danger
              ghost
              disabled={pauseLoading || formLoading}
              style={{ marginTop: isMobile ? 15 : 0 }}
              onClick={() => {
                setPauseLoading(true);
                saveUser({
                  variables: {
                    investorDetails: {
                      id: userData.investorDetails.id,
                      quarterlySubscriptionPaused:
                        !userData.investorDetails.quarterlySubscriptionPaused,
                    },
                  },
                });
              }}
            >
              {userData?.investorDetails.quarterlySubscriptionPaused
                ? 'Resume quarterly investments'
                : 'Pause quarterly investments'}
            </Button>
          )}
          <Button
            disabled={
              formLoading ||
              pauseLoading ||
              userData.investorDetails.quarterlySubscriptionPaused
            }
            type="primary"
            loading={formLoading}
            onClick={() => form.submit()}
            style={{ minWidth: 150 }}
          >
            Confirm
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default UpdateQuarterlySubscription;

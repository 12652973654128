import { DefaultSeo as NextDefaultSeo } from 'next-seo';

function DefaultSeo() {
  return (
    <NextDefaultSeo
      defaultTitle="Shuttle - Join the club"
      titleTemplate="%s | Shuttle"
      description="Shuttle allows you to invest in exclusive venture capital opportunities - a world typically reserved for the ultra-wealthy"
      openGraph={{
        type: 'website',
        locale: 'en_IE',
        url: process.env.NEXTAUTH_URL,
        siteName: 'Shuttle',
        title: 'Shuttle - Join the club',
        description:
          'Shuttle allows you to invest in exclusive venture capital opportunities - a world typically reserved for the ultra-wealthy',
        images: [
          {
            url: `${process.env.NEXTAUTH_URL}/images/social.jpg`,
            width: 1200,
            height: 630,
            alt: 'Shuttle',
          },
        ],
      }}
      twitter={{
        handle: '@club_shuttle',
        site: '@club_shuttle',
        cardType: 'summary_large_image',
      }}
      additionalMetaTags={[
        {
          name: 'robots',
          content: 'index, follow',
        },
      ]}
    />
  );
}

export default DefaultSeo;

import { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { message, Row, Col, Grid, Space, Typography, Input, Form } from 'antd';
import posthog from 'posthog-js';

import { fetchPostJSON } from 'utils/apiHelpers';

import theme from 'styles/theme';
import {
  FooterContainer,
  Wrapper,
  LinksRow,
  FooterLink,
  SocialIcon,
  BuildVersion,
  SubscribeButton,
  AstronautImage,
} from './styles';

import astronautImage from 'assets/images/characters/astronaut.png';
import { useLogoState } from 'context/LogoContext';
import { useInView } from 'framer-motion';

const handleSocialClick = (platform: string) => {
  posthog.capture('social_link_clicked', {
    platform,
  });
};

export const renderSocialLink = (src, url, platform) => (
  <Link
    href={url}
    target="_blank"
    rel="noreferrer"
    onClick={() => handleSocialClick(platform)}
  >
    <SocialIcon src={src} alt="Logo" width={15} height={15} />
  </Link>
);

interface FooterProps {
  $homepage?: boolean;
}

function Footer({ $homepage = false }: FooterProps) {
  const screens = Grid.useBreakpoint();
  const [form] = Form.useForm();

  const footerRef = useRef(null);
  const isInView = useInView(footerRef, { amount: 0.5 });

  const [loading, setLoading] = useState(false);
  const { setIsSolidBackground } = useLogoState();

  useEffect(() => {
    if (isInView) {
      setIsSolidBackground(true);
    } else {
      setIsSolidBackground(false);
    }
  }, [isInView, setIsSolidBackground]);

  const subscribeToNewsletter = async (fields) => {
    setLoading(true);
    try {
      const response = await fetchPostJSON(`api/beehiiv/subscribe`, null, {
        email: fields.email,
      });
      if (response.data) {
        message.success("You're in! Thanks for subscribing.");
        form.resetFields();

        // Track successful newsletter subscription
        posthog.capture('newsletter_subscribed', {
          email: fields.email,
        });
      }
    } catch (error) {
      console.error('Error subscribing to newsletter:', error);
      message.error('Oops! Something went wrong. Please try again.');

      // Track failed subscription attempt
      posthog.capture('newsletter_subscription_failed', {
        error: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const isMobile = screens.md === false;

  return (
    <>
      <FooterContainer
        ref={footerRef}
        $isMobile={isMobile}
        $isInView={isInView}
        $homepage={$homepage}
      >
        <Wrapper $isMobile={isMobile}>
          <AstronautImage
            src={astronautImage}
            alt="Astronaut"
            $screens={screens}
          />
          <Row gutter={[0, 20]}>
            <Col
              sm={24}
              lg={10}
              xl={10}
              xxl={12}
              style={{ marginBottom: screens.xs ? 0 : screens.sm ? 40 : 0 }}
            >
              <Row>
                <Space direction="vertical" size={0} style={{ width: '100%' }}>
                  <Typography.Title
                    type="secondary"
                    style={{
                      fontSize: 16,
                    }}
                  >
                    Newsletter
                  </Typography.Title>
                  <Typography.Title level={3} style={{ marginBottom: 25 }}>
                    Join us and subscribe <br /> to our newsletter
                  </Typography.Title>
                  <Form form={form} onFinish={subscribeToNewsletter}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          type: 'email',
                          message: 'The input is not valid e-mail!',
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: screens.xl
                            ? '100%'
                            : screens.lg
                              ? '70%'
                              : '100%',
                          maxWidth: 400,
                          padding: '10px 10px 10px 20px',
                        }}
                        placeholder="Provide your e-mail address"
                        suffix={
                          <SubscribeButton
                            icon={
                              <Image
                                src="/images/footer/arrow-right.svg"
                                alt="Arrow right"
                                width={15}
                                height={15}
                              />
                            }
                            type="primary"
                            shape="circle"
                            size="small"
                            htmlType="submit"
                            loading={loading}
                          />
                        }
                      />
                    </Form.Item>
                  </Form>
                </Space>
              </Row>
            </Col>
            <Col sm={24} lg={14} xl={14} xxl={12}>
              <LinksRow style={{ marginBottom: isMobile ? 20 : 60 }}>
                <Col
                  xs={24}
                  sm={12}
                  lg={12}
                  xl={14}
                  style={{ marginBottom: isMobile ? 30 : 0 }}
                >
                  <Typography.Title
                    type="secondary"
                    style={{
                      fontSize: 16,
                      fontWeight: 500,
                      marginBottom: 20,
                    }}
                  >
                    General
                  </Typography.Title>
                  <Space direction="vertical" size={10}>
                    <Link
                      passHref
                      legacyBehavior
                      href="/insights/what-is-shuttle"
                    >
                      <FooterLink>About</FooterLink>
                    </Link>
                    <FooterLink
                      onClick={() => {
                        posthog.capture('support_link_clicked', {
                          source: 'footer',
                        });
                        window.BrevoConversations('openChat');
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      Support
                    </FooterLink>
                    {process.env.ACTION_ENVIRONMENT !== 'production' && (
                      <FooterLink $isBuildVersion>
                        {process.env.GITHUB_REF_NAME}
                      </FooterLink>
                    )}
                  </Space>
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  lg={12}
                  xl={10}
                  style={{ marginBottom: screens.md ? 0 : 30 }}
                >
                  <Typography.Title
                    type="secondary"
                    style={{
                      fontSize: 16,
                      fontWeight: 500,
                      marginBottom: 20,
                    }}
                  >
                    Legal
                  </Typography.Title>
                  <Space direction="vertical" size={10}>
                    <Link passHref legacyBehavior href="/legal/risk-warning">
                      <FooterLink>Risk Warning</FooterLink>
                    </Link>
                    <Link passHref legacyBehavior href="/legal/complaints">
                      <FooterLink>Complaints</FooterLink>
                    </Link>
                  </Space>
                </Col>
              </LinksRow>
              <LinksRow style={{ marginBottom: isMobile ? 20 : 40 }}>
                <Col xs={24} sm={12} lg={12} xl={14}>
                  <Image
                    src="/images/footer/wordmark.svg"
                    alt="Logo"
                    width={110}
                    height={35}
                    style={{ marginTop: -4, marginBottom: isMobile ? 20 : 0 }}
                  />
                </Col>
                <Col xs={24} sm={12} lg={12} xl={10}>
                  <Space size={25}>
                    {renderSocialLink(
                      '/images/footer/linkedin.svg',
                      'https://www.linkedin.com/company/shuttleclub',
                      'linkedin'
                    )}
                    {renderSocialLink(
                      '/images/footer/instragram.svg',
                      'https://www.instagram.com/club.shuttle',
                      'instagram'
                    )}
                    {renderSocialLink(
                      '/images/footer/twitter.svg',
                      'https://x.com/club_shuttle',
                      'twitter'
                    )}
                  </Space>
                  <Typography.Paragraph
                    type="secondary"
                    style={{ margin: '20px 0 0 0' }}
                  >
                    PitchedIt Ltd t/a Shuttle Finance is regulated by the
                    Central Bank of Ireland. Registered with the Companies
                    Registration Office in Ireland. Company number 697037.
                  </Typography.Paragraph>
                </Col>
              </LinksRow>
              <LinksRow gutter={[0, 20]}>
                <Col xs={24} sm={12} lg={12} xl={14}>
                  <Typography.Text type="secondary">
                    &copy; PitchedIt Ltd (t/a Shuttle Finance)
                  </Typography.Text>
                </Col>
                <Col xs={24} sm={12} lg={12} xl={10}>
                  <Space>
                    <Link
                      href="/legal/privacy-policy"
                      passHref
                      legacyBehavior
                      style={{ color: theme.colors.textSecondary }}
                    >
                      <Typography.Link type="secondary">
                        Privacy policy
                      </Typography.Link>
                    </Link>
                    <span style={{ color: theme.colors.textSecondary }}>|</span>
                    <Link
                      href="/legal/terms-of-use"
                      passHref
                      legacyBehavior
                      style={{ color: theme.colors.textSecondary }}
                    >
                      <Typography.Link type="secondary">
                        Terms of use
                      </Typography.Link>
                    </Link>
                  </Space>
                </Col>
              </LinksRow>
            </Col>
          </Row>
        </Wrapper>
      </FooterContainer>
      <FooterContainer $isMobile={isMobile} $homepage={$homepage}>
        <Wrapper $isMobile={isMobile}>
          <Row>
            <Col
              sm={24}
              lg={{ span: 14, push: 10 }}
              xxl={{ span: 12, push: 12 }}
            >
              <Space
                align="start"
                direction={isMobile ? 'vertical' : 'horizontal'}
              >
                <Typography.Title
                  level={5}
                  style={{ width: isMobile ? 'auto' : 130, fontSize: 16 }}
                >
                  Risk warning
                </Typography.Title>
                <Typography.Paragraph
                  type="secondary"
                  style={{ marginBottom: 0 }}
                >
                  All investments entail risks, including the risk of partial or
                  entire loss of the money invested. Your investment is not
                  covered by a deposit guarantee scheme or by an investor
                  compensation scheme. Please read our full{' '}
                  <Link
                    href="/legal/risk-warning"
                    style={{
                      textDecoration: 'underline',
                    }}
                  >
                    risk warning
                  </Link>{' '}
                  for more details.
                </Typography.Paragraph>
              </Space>
            </Col>
          </Row>
        </Wrapper>
      </FooterContainer>
    </>
  );
}

export default Footer;

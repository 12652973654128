import { gql } from '@apollo/client';

export const GET_WAITLIST_SUBMISSION = gql`
  query getWaitlistSubmission($id: ID!) {
    waitlistSubmission(id: $id) {
      data {
        id
        attributes {
          createdAt
          email
          firstName
          lastName
          hash
        }
      }
    }
  }
`;

export const GET_WAITLIST_SUBMISSION_BY_EMAIL_QUERY = gql`
  query getWaitlistSubmissionByEmail($email: String!) {
    getWaitlistSubmissionByEmail(email: $email) {
      data {
        id
        attributes {
          createdAt
          email
          firstName
          lastName
          hash
        }
      }
    }
  }
`;

export const GET_WAITLIST_POSITION_QUERY = gql`
  query getWaitlistPosition($createdAt: DateTime!) {
    waitlistPosition: waitlistSubmissions(
      filters: { createdAt: { lt: $createdAt } }
    ) {
      meta {
        pagination {
          total
        }
      }
    }
  }
`;

export const GET_WAITLIST_SUM_QUERY = gql`
  query getWaitlistSum {
    waitlistSum: getWaitlistSum
  }
`;

export const JOIN_WAITLIST_QUERY = gql`
  mutation createWaitlistSubmission(
    $email: String!
    $firstName: String!
    $lastName: String!
    $hash: String
    $marketingOptIn: Boolean
  ) {
    createWaitlistSubmission(
      data: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        hash: $hash
        marketingOptIn: $marketingOptIn
      }
    ) {
      data {
        id
        attributes {
          email
          firstName
          lastName
          hash
        }
      }
    }
  }
`;

export const LINK_INVITE_CODE_QUERY = gql`
  mutation linkInviteCode($email: String!, $hash: String!) {
    linkInviteCode: linkWaitlistSubmissionReferral(email: $email, hash: $hash) {
      ok
      error
    }
  }
`;

export const initBrevoSDK = () => {
  // Remove any existing Brevo SDK scripts
  const existingSDKScript = document.querySelector(
    'script[src*="sdk-loader.js"]'
  );
  if (existingSDKScript) {
    existingSDKScript.remove();
  }

  // Initialize Brevo SDK
  window.Brevo = window.Brevo || [];
  window.Brevo.push([
    'init',
    {
      client_key: 'y3xg0b119zq36t7q4xk8v0qu',
    },
  ]);

  const sdkScript = document.createElement('script');
  sdkScript.async = true;
  sdkScript.src = 'https://cdn.brevo.com/js/sdk-loader.js';
  document.head.appendChild(sdkScript);

  return sdkScript;
};

export function cleanupBrevoSDK() {
  window.Brevo = undefined;
}

export function trackBrevoEvent(
  eventName: string,
  properties?: any,
  eventData?: any
) {
  if (typeof window !== 'undefined' && window.Brevo) {
    console.log('Tracking event:', eventName, properties, eventData);
    window.Brevo.push(['track', eventName, properties, eventData]);
  }
}

import * as R from 'ramda';
import moment from 'moment';

import { EUROPEAN_COUNTRIES } from 'config';

import { currencyFormatter } from 'utils/strings';

export const nameValidation = (fieldName) => [
  {
    message: `${fieldName} too short`,
    async validator(_, value) {
      if (value.length !== 0 && value.length < 2) {
        throw new Error('Name too short');
      }
    },
  },
  {
    message: `${fieldName} too long`,
    async validator(_, value) {
      if (value.length > 25) {
        throw 'Name too long';
      }
    },
  },
];

export const countryValidation = (type = 'investment') => [
  { required: true, message: 'This is a required field!' },
  {
    message:
      type === 'investment'
        ? 'Due to regulations we can only accept investors who reside within the European Union'
        : 'Due to regulations we can only accept companies incorporated within the European Union',
    async validator(_, value) {
      if (value && !EUROPEAN_COUNTRIES.includes(value)) {
        throw 'Non European investor';
      }
    },
  },
];

export const nonSophisticatedAssessmentValidation = [
  { required: true, message: 'This is a required field!' },
  {
    message:
      'Unfortunately you can not invest unless you indicate that you understand and accept the risks associated with this investment.',
    async validator(_, value) {
      if (!value) {
        throw 'Value is not true';
      }
    },
  },
];

export const urlValidation = [
  {
    message: 'Please enter a valid URL',
    async validator(_, value) {
      const expression =
        /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{2,20}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
      const regex = new RegExp(expression);
      if (value && !value.match(regex)) {
        throw 'Invalid URL format';
      }
    },
  },
];

export const linkedinUrlValidation = [
  { required: true, message: 'This is a required field' },
  {
    message: 'Please enter a valid LinkedIn profile URL',
    async validator(_, value) {
      const expression =
        /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/gi;
      const regex = new RegExp(expression);
      if (value && !value.match(regex)) {
        throw 'Invalid URL format';
      }
    },
  },
];

export const costValidation = (netWorth, isTopUp = false) => [
  { required: true, message: 'This is a required field!' },
  {
    message: isTopUp
      ? 'The minimum deposit is €250.'
      : 'There is a minimum investment of €250 per quarter.',
    async validator(_, value) {
      if (value && value < 250) {
        throw 'Value less than €250';
      }
    },
  },
  {
    message: 'We only allow investments in €50 increments.',
    async validator(_, value) {
      if (value && value > 250 && value % 50 !== 0) {
        throw 'Value not divisible by 50';
      }
    },
  },
  {
    message: 'We can only process up to €10,000 per deposit',
    async validator(_, value) {
      if (value && value > 10000) {
        throw 'Value greater than €10,000';
      }
    },
  },
  {
    message: isTopUp
      ? 'This exceeds 5% of your calculated net worth.'
      : 'This exceeds 10% of your net worth on an annualised basis.',
    async validator(_, value) {
      if (isTopUp) {
        if (value && value > netWorth * 0.05 && value <= 10000) {
          throw 'Value more than 5% of net worth';
        }
      } else {
        if (value && value * 4 > netWorth * 0.1 && value <= 10000) {
          throw 'Value more than 10% of net worth';
        }
      }
    },
  },
];

export const fundingTargetValidation = [
  { required: false, message: 'This is a required field!' },
  {
    message: 'We only work with companies looking to raise at least €250,000',
    async validator(_, value) {
      if (value && value < 250000) {
        throw 'Value less than €250,000';
      }
    },
  },
];

export const allocationValidation = [
  { required: false, message: 'This is a required field!' },
  {
    message: 'The minimum you can raise via our platform is €20,000',
    async validator(_, value) {
      if (value && value < 20000) {
        throw 'Value less than €20,000';
      }
    },
  },
  {
    message: 'The maximum you can raise via our platform is €5,000,000',
    async validator(_, value) {
      if (value && value > 5000000) {
        throw 'Value more than €5,000,000';
      }
    },
  },
];

const checkPathsForEmptyOrNil = (paths, obj) => {
  // Check if a single value at the path is empty or nil
  const isValueEmptyOrNil = (path) => {
    const value = R.path(path, obj);
    return R.isNil(value) || R.isEmpty(value);
  };

  // Check if any path leads to an empty or nil value
  return R.any(isValueEmptyOrNil, paths);
};

export const onboardingFieldChange = ({ form, fields, setComplete }) => {
  const fieldValues = form.getFieldsValue(fields);
  const sectionComplete = !checkPathsForEmptyOrNil(fields, fieldValues);

  // console.log(fieldValues, sectionComplete);

  setComplete?.(sectionComplete);
};

export const imageValidation = (
  fileSize: number,
  allowedTypes = ['image/jpeg', 'image/png']
) => [
  {
    validator: async (_, fileList) => {
      if (!fileList || fileList.length === 0) {
        return Promise.resolve();
      }

      const file = fileList[0]?.originFileObj;
      if (!file) {
        return Promise.resolve();
      }

      if (!allowedTypes.includes(file.type)) {
        const formattedTypes = allowedTypes
          .map((type) => type.split('/')[1].toUpperCase())
          .join('/');
        return Promise.reject(
          new Error(`Only ${formattedTypes} files are allowed`)
        );
      }

      const isWithinSizeLimit = file.size / 1024 / 1024 < fileSize;
      if (!isWithinSizeLimit) {
        return Promise.reject(
          new Error(`Image must be smaller than ${fileSize}MB`)
        );
      }

      return Promise.resolve();
    },
  },
];

import { gql } from '@apollo/client';

export const GET_REFERRALS_COUNT_QUERY = gql`
  query getReferrals($filters: ReferralFiltersInput) {
    referrals(filters: $filters) {
      data {
        id
      }
      meta {
        pagination {
          total
        }
      }
    }
  }
`;

export const GET_REFERRALS_QUERY = gql`
  query getReferrals(
    $filters: ReferralFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    referrals(pagination: $pagination, filters: $filters, sort: $sort) {
      data {
        id
        attributes {
          email
          payout
          carry
          status
          code
          joinBonusPaid
          earnings
          source
          waitlistOwner {
            data {
              id
              attributes {
                email
                hash
              }
            }
          }
          owner {
            data {
              id
              attributes {
                email
                accountDetails {
                  firstName
                  lastName
                }
                OPP {
                  merchantId
                }
                avatar {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          user {
            data {
              id
              attributes {
                accountDetails {
                  firstName
                  lastName
                }
                avatar {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
      meta {
        pagination {
          page
          pageSize
          pageCount
          total
        }
      }
    }
  }
`;

export const GET_REFERRAL_QUERY = gql`
  query getReferral($id: ID!) {
    referral(id: $id) {
      data {
        id
        attributes {
          email
          name
          code
          payout
          carry
          status
          source
          member {
            data {
              id
              attributes {
                OPP {
                  merchantId
                  profileId
                  contactId
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_REFERRAL_QUERY = gql`
  mutation createManyReferrals($referrals: [ReferralInput!]!) {
    createManyReferrals(referrals: $referrals) {
      referrals {
        code
        email
        status
        payout
        carry
        source
      }
      errors {
        email
        error
      }
    }
  }
`;

export const UPDATE_REFERRAL_QUERY = gql`
  mutation updateReferral($id: ID!, $status: ENUM_REFERRAL_STATUS) {
    updateReferral(id: $id, data: { status: $status }) {
      data {
        id
      }
    }
  }
`;

export const GET_SYNDICATE_QTRLY_AND_INVEST_SUM_QUERY = gql`
  query getSyndicateQuarterlySumAndInvestmentSum($syndicateLead: ID) {
    getSyndicateQuarterlySumAndInvestmentSum(syndicateLead: $syndicateLead) {
      investmentSum
      quarterlySum
      referralSum
    }
  }
`;

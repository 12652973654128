import type { Session } from 'next-auth';

export const initBrevoConversations = (session: Session) => {
  const brevoConfig: typeof window.BrevoConversationsSetup = {
    startHidden: false,
    buttonSize: 50,
    disableGaTracking: true,
    deferredLoading: true,
  };

  if (session?.user) {
    brevoConfig.visitorId = `shuttle_${session.user.id}`;
  } else {
    brevoConfig.visitorId = null;
  }

  // Remove any existing Brevo Conversations scripts
  const existingScript = document.querySelector(
    'script[src*="brevo-conversations.js"]'
  );
  if (existingScript) {
    existingScript.remove();
  }

  // Reset Brevo Conversations state
  window.BrevoConversations = undefined;
  window.BrevoConversationsSetup = brevoConfig;
  window.BrevoConversationsID = '678a4aeda0f0b4e9c707d974';

  window.BrevoConversations =
    window.BrevoConversations ||
    function (...args: unknown[]) {
      (window.BrevoConversations.q = window.BrevoConversations.q || []).push(
        args
      );
    };

  const conversationsScript = document.createElement('script');
  conversationsScript.async = true;
  conversationsScript.src =
    'https://conversations-widget.brevo.com/brevo-conversations.js';
  document.head.appendChild(conversationsScript);

  return conversationsScript;
};

export function cleanupBrevoConversations() {
  window.BrevoConversations = undefined;
  window.BrevoConversationsSetup = undefined;
  window.BrevoConversationsID = undefined;
}

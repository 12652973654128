import styled, { keyframes } from 'styled-components';
import { Typography } from 'antd';
import { timingFunctions } from 'polished';

import theme from 'styles/theme';

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 0.97;
    transform: translateY(0);
  }
`;

interface ContainerProps {
  $isMobile: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  bottom: ${({ $isMobile }) => ($isMobile ? '30px' : '20px')};
  right: ${({ $isMobile }) => ($isMobile ? '30px' : '105px')};
  width: ${(props) => (props.$isMobile ? 'calc(100vw - 60px)' : '330px')};
  padding: 25px 30px 30px;
  background: ${theme.backgrounds.onboarding};
  box-shadow: ${theme.shadows.card};
  border-radius: ${(props) => (props.$isMobile ? '30px' : '30px')};
  z-index: 10001;
  opacity: 0;
  animation: ${fadeInUp} 1s ${timingFunctions('easeInOutCubic')} forwards;
  animation-delay: 6s;
`;

export const Title = styled(Typography.Title)`
  &&& {
    margin-bottom: 5px;
  }
`;

export const Description = styled(Typography.Paragraph)`
  &&& {
    margin-bottom: 15px;
    font-size: 14px;
    color: #6b7279;
  }
`;

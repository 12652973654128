import { useState, useEffect, useMemo } from 'react';
import Image from 'next/image';
import {
  Divider,
  Checkbox,
  Slider,
  Form,
  Typography,
  Modal,
  Space,
  Row,
  Col,
  Input,
  InputNumber,
  Button,
  message,
  Grid,
  Alert,
} from 'antd';
import { WhatsAppOutlined } from '@ant-design/icons';
import { useRouter } from 'next/router';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import copyText from 'copy-to-clipboard';
import crypto from 'crypto';
import posthog from 'posthog-js';

import {
  JOIN_WAITLIST_QUERY,
  GET_WAITLIST_SUBMISSION,
  LINK_INVITE_CODE_QUERY,
  GET_WAITLIST_SUBMISSION_BY_EMAIL_QUERY,
  GET_WAITLIST_SUM_QUERY,
  GET_REFERRALS_COUNT_QUERY,
  WAITLIST_PAGE_QUERY,
} from 'queries';

import ReturnProjector from './ReturnProjector';

import { flattenEntities } from 'utils/graphql';

import theme from 'styles/theme';
import { WhatsAppButton } from './styles';

export interface WaitlistModalProps {
  isOpen: boolean;
  setIsOpen(isOpen: boolean): void;
  email?: string;
  onComplete(): void;
}

function WaitlistModal({
  isOpen,
  setIsOpen,
  email,
  onComplete,
}: WaitlistModalProps) {
  const screens = Grid.useBreakpoint();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [estimatedReferrals, setEstimatedReferrals] = useState(30);
  const [form] = Form.useForm();

  const { data: pageData } = useQuery(WAITLIST_PAGE_QUERY);
  const copy = pageData?.waitlistPage.data.attributes.copy;

  const [getWaitlistSubmission, { data }] = useLazyQuery(
    GET_WAITLIST_SUBMISSION
  );
  const [getWaitlistSubmissionByEmail, { data: dataByEmail }] = useLazyQuery(
    GET_WAITLIST_SUBMISSION_BY_EMAIL_QUERY
  );
  const [getReferralsData, { data: referralsData }] = useLazyQuery(
    GET_REFERRALS_COUNT_QUERY
  );
  const [linkInviteCode] = useMutation(LINK_INVITE_CODE_QUERY);

  const waitlistUser = useMemo(() => {
    const waitlistData =
      flattenEntities(data?.waitlistSubmission)?.data ||
      flattenEntities(dataByEmail?.getWaitlistSubmissionByEmail)?.data;

    if (typeof window !== 'undefined' && waitlistData) {
      localStorage.setItem('Shuttle-waitlistId', waitlistData.id);
      posthog.identify(`waitlist-${waitlistData.id}`, {
        email: waitlistData.email,
      });
    }

    return waitlistData;
  }, [data, dataByEmail]);

  const [createWaitlistSubmission] = useMutation(JOIN_WAITLIST_QUERY, {
    async onCompleted(data) {
      setLoading(false);
      message.success("Congrats, you're on the waitlist!");

      const waitlistId = data.createWaitlistSubmission.data.id;
      getWaitlistSubmission({ variables: { id: waitlistId } });

      const referralCode =
        router.query.referralCode ||
        localStorage.getItem('Shuttle-referralCode');
      if (referralCode) {
        const email = data.createWaitlistSubmission.data.attributes.email;
        await linkInviteCode({
          variables: {
            email,
            hash: referralCode,
          },
        });
        posthog.capture('waitlist_referral_code_linked');
      }

      onComplete();
    },
    async onError(error) {
      if (
        error.message === 'Duplicate entry' ||
        error.message === 'Internal Server Error' // TODO: Why is Strapi not returning the correct error?
      ) {
        await getWaitlistSubmissionByEmail({
          variables: { email: form.getFieldValue('email') },
        });
      } else {
        message.error('Something went wrong. Please try again.');
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    const waitlistId = localStorage.getItem('Shuttle-waitlistId');
    if (waitlistId) {
      getWaitlistSubmission({ variables: { id: waitlistId } });
      getReferralsData({
        variables: {
          filters: {
            waitlistOwner: {
              id: {
                eq: waitlistId,
              },
            },
          },
        },
      });
    }
  }, []);

  const onFormFinish = async (values) => {
    setLoading(true);

    // Track waitlist signup attempt
    posthog.capture('waitlist_signup', {
      marketing_opt_in: values.marketingOptIn,
    });

    // Generate a buffer of random bytes
    const buffer = crypto.randomBytes(Math.ceil((8 * 3) / 4));

    // Convert the buffer to a Base64 string
    const base64String = buffer.toString('base64');

    // Replace '+' and '/' with alphanumeric characters to avoid URL issues
    // and remove '=' padding to get a cleaner string, then trim to desired length
    const hash = base64String
      .replace(/\+/g, '0')
      .replace(/\//g, 'A')
      .substring(0, 8);

    createWaitlistSubmission({
      variables: {
        ...values,
        hash,
      },
    });
  };

  // Add tracking for WhatsApp share
  const handleWhatsAppShare = () => {
    posthog.capture('waitlist_whatsapp_share', {
      user_email: waitlistUser.email,
      referral_count: referralsData?.referrals?.meta?.pagination?.total,
    });
  };

  // Add tracking for link copy
  const handleLinkCopy = () => {
    posthog.capture('waitlist_link_copied', {
      user_email: waitlistUser.email,
      referral_count: referralsData?.referrals?.meta?.pagination?.total,
    });

    copyText(`${process.env.NEXTAUTH_URL}/?referralCode=${waitlistUser.hash}`);
    message.success('Invite link copied to clipboard!');
  };

  const onChangeComplete = (value: number) => {
    setEstimatedReferrals(value);
  };

  const isMobile = screens.sm === false;

  return (
    <Modal
      closeIcon={null}
      title={null}
      open={isOpen}
      footer={null}
      width={600}
      onCancel={() => setIsOpen(false)}
      styles={{
        body: { padding: 30 },
      }}
    >
      <Form form={form} onFinish={onFormFinish} layout="vertical">
        {!waitlistUser ? (
          <Row gutter={[30, 0]}>
            <Col>
              <Alert
                message={copy?.onboarding}
                type="info"
                showIcon
                style={{ marginBottom: 20 }}
              />
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                style={{ marginBottom: 15, width: '100%' }}
                name="firstName"
                label="First name"
                rules={[
                  {
                    required: true,
                    message: 'This is a required field',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                style={{ marginBottom: 15, width: '100%' }}
                name="lastName"
                label="Last name"
                rules={[
                  {
                    required: true,
                    message: 'This is a required field',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={24}>
              <Form.Item
                style={{ marginBottom: 25, width: '100%' }}
                name="email"
                label="Email address"
                initialValue={email}
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Please input a valid email',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={20}>
              <Form.Item
                style={{ marginBottom: 15 }}
                name="marketingOptIn"
                valuePropName="checked"
              >
                <Checkbox style={{ color: '#6B7279' }}>{copy?.optIn}</Checkbox>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Button
                htmlType="submit"
                type="primary"
                loading={loading}
                style={{ minWidth: 200, marginTop: 10 }}
                block={isMobile}
              >
                {copy?.cta}
              </Button>
            </Col>
          </Row>
        ) : (
          <Space size={20} direction="vertical" style={{ width: '100%' }}>
            <div
              style={{
                textAlign: 'center',
                padding: '50px 20px',
                position: 'relative',
                background: theme.backgrounds.heroTeal,
                borderRadius: 15,
                zIndex: 1,
              }}
            >
              <Typography.Title
                level={isMobile ? 4 : 2}
                style={{
                  color: 'white',
                  marginBottom: 10,
                }}
              >
                {copy?.registered.hero.title}
              </Typography.Title>
              <Typography.Paragraph
                style={{
                  color: '#ddd',
                  textShadow: '0px 0px  5px black',
                  maxWidth: 450,
                  margin: '0 auto',
                }}
              >
                {copy?.registered.hero.description}
              </Typography.Paragraph>
            </div>

            <Typography.Paragraph
              style={{ margin: '-15px 0 0 0' }}
              type="secondary"
            >
              {copy?.registered.reservation} {waitlistUser.email}.{' '}
              <Typography.Link
                onClick={() => {
                  localStorage.removeItem('Shuttle-waitlistId');
                  window.location.reload();
                }}
              >
                Not you?
              </Typography.Link>
            </Typography.Paragraph>

            <Row align="middle" justify="space-between">
              <Col xs={24} md={16}>
                <Space>
                  <a
                    href={`https://wa.me/?text=${encodeURIComponent(`Guys! I've signed up for Shuttle so I can co-invest alongside VCs in exciting startups. Join using my friend link to get yourself a 10% discount: ${process.env.NEXTAUTH_URL}/?referralCode=${waitlistUser.hash}`)}`}
                    target="_blank"
                    rel="noreferrer"
                    onClick={handleWhatsAppShare}
                  >
                    <WhatsAppButton type="primary" icon={<WhatsAppOutlined />}>
                      {isMobile ? 'WhatsApp' : 'Share on WhatsApp'}
                    </WhatsAppButton>
                  </a>
                  <Button type="primary" onClick={handleLinkCopy}>
                    Copy link
                  </Button>
                </Space>
              </Col>
              <Col
                xs={24}
                md={8}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: screens.md ? 'end' : 'start',
                  marginTop: screens.md ? 0 : 10,
                }}
              >
                <Typography.Text
                  type="secondary"
                  style={{ margin: '0 10px 0 0', display: 'inline-block' }}
                >
                  {copy?.registered.share.invites}
                </Typography.Text>
                <Typography.Title
                  level={3}
                  style={{
                    margin: 0,
                    display: 'inline-block',
                  }}
                >
                  {referralsData?.referrals?.meta?.pagination?.total || 0}
                </Typography.Title>
              </Col>
            </Row>
            <Typography.Paragraph
              style={{ margin: 0, color: theme.colors.bodyText }}
            >
              {copy?.registered.share.description}
            </Typography.Paragraph>
            <div>
              <Divider style={{ marginTop: 0 }} />
              <Typography.Title level={4} style={{ marginBottom: 5 }}>
                {copy?.registered.profitSimulator.title}
              </Typography.Title>
              <Typography.Paragraph style={{ margin: 0 }} type="secondary">
                {copy?.registered.profitSimulator.description}
              </Typography.Paragraph>
              <Slider
                min={10}
                max={100}
                step={10}
                value={estimatedReferrals}
                onChange={onChangeComplete}
                tooltip={{ formatter: (value) => `${value} friends` }}
                style={{ marginBottom: 25 }}
              />
              <ReturnProjector sumOverride={estimatedReferrals * 250} />
            </div>
          </Space>
        )}
      </Form>
    </Modal>
  );
}

export default WaitlistModal;

import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useSession } from 'next-auth/react';

import { UserProps } from 'types';

import { USER_DATA_QUERY } from 'queries';

const useUserData = () => {
  const { data: session, status } = useSession();
  const skip = status !== 'authenticated';

  const { data, loading, error, refetch } = useQuery<{ me: UserProps }>(
    USER_DATA_QUERY,
    {
      skip,
      variables: { id: session?.user.id },
      // fetchPolicy: 'network-only', // Ensures fresh data is fetched each time
      // nextFetchPolicy: 'cache-first', // Fallback to cache after the initial fetch
    }
  );
  const userData = data?.me;

  // Refetch user data whenever the session changes
  useEffect(() => {
    if (!skip) {
      refetch();
    }
  }, [session, skip, refetch]);

  // useEffect(() => {
  //   if (
  //     userData &&
  //     typeof window !== 'undefined' &&
  //     window.BrevoConversations
  //   ) {
  //     try {
  //       window.BrevoConversations('updateIntegrationData', {
  //         email: userData.email,
  //       });
  //     } catch (error) {
  //       console.warn('Failed to update Brevo integration data:', error);
  //     }
  //   }

  //   if (userData && typeof window !== 'undefined') {
  //     const brevo = window.Brevo;
  //     if (brevo && 'identify' in brevo) {
  //       brevo.push(function () {
  //         brevo.identify({
  //           identifiers: {
  //             email_id: userData.email,
  //           },
  //         });
  //       });
  //     }
  //   }
  // }, [userData]);

  return { userData, loading, error, refetch };
};

export default useUserData;

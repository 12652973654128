import { gql } from '@apollo/client';

export const getProjectFragment = gql`
  fragment GetProjectFields on Project {
    email
    owner {
      data {
        id
      }
    }
    name
    legalName
    slug
    incorporationCountry
    companyNumber
    currency
    fundingTarget
    fundingCommitted
    availableAllocation
    minAllocation
    maxAllocation
    description
    tagline
    problem
    currentSolutions
    solution
    targetCustomer
    businessModel
    marketOpportunity
    whyNow
    productTraction
    customerTraction
    revenueTraction
    founderStory
    founderMarketFit
    investmentTraction
    burnAndRunway
    nearTermAmbition
    longTermAmbition
    founderProfiles {
      name
      position
      linkedInUrl
      avatar {
        data {
          attributes {
            url
          }
        }
      }
    }
    icon {
      data {
        attributes {
          url
          width
          height
        }
      }
    }
    logo {
      data {
        attributes {
          url
          width
          height
        }
      }
    }
    distributionModel
    faq {
      question
      answer
    }
    verticals {
      data {
        id
        attributes {
          name
        }
      }
    }
    url
    pitchDeck {
      data {
        attributes {
          url
        }
      }
    }
    pitchVideo {
      data {
        attributes {
          url
        }
      }
    }
    pitchVideoYoutube
    OPP {
      accessToken
      merchantId
      projectMerchantId
      projectMerchantProfileId
      bankAccountId
      profileId
    }
    leadInvestor
    leadInvestorUrl
    phase
  }
`;

const notesFragment = gql`
  fragment NotesFragment on Project {
    notes {
      createdOn
      content
      lastPhase
      newPhase
      author {
        data {
          id
          attributes {
            accountDetails {
              firstName
              lastName
            }
            avatar {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PROJECTS_QUERY = gql`
  ${getProjectFragment}
  ${notesFragment}
  query getProjects(
    $pagination: PaginationArg
    $filters: ProjectFiltersInput
    $sort: [String]
    $includeNotes: Boolean!
  ) {
    projects(pagination: $pagination, filters: $filters, sort: $sort) {
      data {
        id
        attributes {
          ...GetProjectFields
          ...NotesFragment @include(if: $includeNotes)
        }
      }
      meta {
        pagination {
          total
        }
      }
    }
  }
`;

export const GET_PROJECT_QUERY = gql`
  ${getProjectFragment}
  query project($id: ID, $slug: String) {
    projects(filters: { or: [{ id: { eq: $id } }, { slug: { eq: $slug } }] }) {
      data {
        id
        attributes {
          ...GetProjectFields
        }
      }
    }
  }
`;

export const GET_PROJECT_BY_OWNER = gql`
  ${getProjectFragment}
  query getProjectForUser($id: ID!) {
    projects(filters: { owner: { id: { eq: $id } } }) {
      data {
        id
        attributes {
          ...GetProjectFields
        }
      }
    }
  }
`;

export const CREATE_PROJECT_QUERY = gql`
  mutation createProject(
    $email: String!
    $name: String!
    $description: String!
    $fullTimeFounders: Boolean!
    $founderProfiles: [ComponentProjectFounderProfilesInput]!
    $verticals: [ID]!
    $incorporationCountry: String!
    $distributionModel: ENUM_PROJECT_DISTRIBUTIONMODEL!
    $url: String
    $fundingTarget: Int!
    $fundingCommitted: Int!
    $hasLeadInvestor: Boolean
    $leadInvestor: String
  ) {
    createProject(
      data: {
        email: $email
        name: $name
        description: $description
        fullTimeFounders: $fullTimeFounders
        founderProfiles: $founderProfiles
        verticals: $verticals
        incorporationCountry: $incorporationCountry
        distributionModel: $distributionModel
        url: $url
        hasLeadInvestor: $hasLeadInvestor
        leadInvestor: $leadInvestor
        fundingTarget: $fundingTarget
        fundingCommitted: $fundingCommitted
      }
    ) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_PROJECT_QUERY = gql`
  ${getProjectFragment}
  mutation updateProject(
    $projectId: ID!
    $name: String
    $founderProfiles: [ComponentProjectFounderProfilesInput]
    $description: String
    $problem: String
    $currentSolutions: String
    $solution: String
    $targetCustomer: String
    $businessModel: String
    $marketOpportunity: String
    $whyNow: String
    $productTraction: String
    $customerTraction: String
    $revenueTraction: String
    $founderStory: String
    $founderMarketFit: String
    $investmentTraction: String
    $burnAndRunway: String
    $nearTermAmbition: String
    $longTermAmbition: String
    $verticals: [ID]
    $faq: [ComponentProjectFaqInput]
    $url: String
    $pitchVideoYoutube: String
    $distributionModel: ENUM_PROJECT_DISTRIBUTIONMODEL
    $OPP: ComponentProjectOppInput
    $leadInvestor: String
    $leadInvestorUrl: String
    $fundingTarget: Int
    $fundingCommitted: Int
    $phase: ENUM_PROJECT_PHASE
    $notes: [ComponentProjectNotesInput]
  ) {
    updateProject(
      id: $projectId
      data: {
        name: $name
        founderProfiles: $founderProfiles
        description: $description
        problem: $problem
        currentSolutions: $currentSolutions
        solution: $solution
        targetCustomer: $targetCustomer
        businessModel: $businessModel
        marketOpportunity: $marketOpportunity
        whyNow: $whyNow
        productTraction: $productTraction
        customerTraction: $customerTraction
        revenueTraction: $revenueTraction
        founderStory: $founderStory
        founderMarketFit: $founderMarketFit
        investmentTraction: $investmentTraction
        burnAndRunway: $burnAndRunway
        nearTermAmbition: $nearTermAmbition
        longTermAmbition: $longTermAmbition
        verticals: $verticals
        faq: $faq
        url: $url
        pitchVideoYoutube: $pitchVideoYoutube
        distributionModel: $distributionModel
        OPP: $OPP
        leadInvestor: $leadInvestor
        leadInvestorUrl: $leadInvestorUrl
        fundingTarget: $fundingTarget
        fundingCommitted: $fundingCommitted
        notes: $notes
        phase: $phase
      }
    ) {
      data {
        id
        attributes {
          ...GetProjectFields
        }
      }
    }
  }
`;

export const GET_PROJECT_UPDATES = gql`
  query getProjectUpdates(
    $pagination: PaginationArg
    $filters: ProjectUpdateFiltersInput
    $sort: [String]
  ) {
    projectUpdates(pagination: $pagination, filters: $filters, sort: $sort) {
      data {
        id
        attributes {
          title
          content
          createdAt
          project {
            data {
              id
              attributes {
                name
                icon {
                  data {
                    attributes {
                      url
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
      meta {
        pagination {
          page
          pageSize
          total
          pageCount
        }
      }
    }
  }
`;

import parsePhoneNumber from 'libphonenumber-js';
import { lookup } from 'country-data';
import { FormInstance } from 'antd/lib/form/Form';
import moment from 'moment';
import { MutationFunction } from '@apollo/client';

import { PITCHEDIT_FEES } from 'config';

import { UserProps, ReferralProps } from 'types';
import {
  Merchant,
  MerchantInput,
  BankAccount,
  BankAccountInput,
  // MandateTransactionInput,
  TransactionInput,
  Transaction,
  TransactionPaymentDetails,
  // PaymentReference,
  // PaymentReferenceInput,
} from 'types/opp';

import { formatAmountForOPP } from 'utils/oppHelpers';

// import buildMandate from 'lib/opp/buildMandate';

import { fetchGetJSON, fetchPostJSON } from 'utils/apiHelpers';

export const createMerchant = async ({
  form,
  userData,
}): Promise<Merchant | null> => {
  const country = lookup.countries({
    alpha2: form.getFieldValue(['accountDetails', 'countryOfResidence']),
  })[0]?.alpha3;
  const countryCallingCode = lookup.countries({
    alpha2: form.getFieldValue(['accountDetails', 'countryCallingCode']),
  })[0].countryCallingCodes[0];
  const phoneNumber = parsePhoneNumber(
    `${countryCallingCode}${form.getFieldValue(['accountDetails', 'phoneNumber'])}`
  )?.number;

  const merchantData: MerchantInput = {
    emailaddress: userData.email,
    country,
    phone: phoneNumber,
    type: 'consumer',
    name_first: form.getFieldValue(['accountDetails', 'firstName']),
    name_last: form.getFieldValue(['accountDetails', 'lastName']),
    settlement_interval: 'continuous',
    notify_url: `${process.env.NEXTAUTH_URL}/api/psp/merchants/webhooks`,
    return_url: `${process.env.NEXTAUTH_URL}/account`,
    metadata: {
      user: userData.id,
    },
  };

  return await fetchPostJSON(
    '/api/psp/merchants?expand[]=profiles&expand[]=contacts',
    null,
    merchantData
  );
};

export const createBankAccount = async (
  merchantId: string,
  bankDetails: {
    iban: string;
    bic: string;
  }
): Promise<BankAccount | null> => {
  const bankAccountData: BankAccountInput = {
    notify_url: `${process.env.NEXTAUTH_URL}/api/psp/merchants/${merchantId}/bankAccounts/webhooks`,
    return_url: `${process.env.NEXTAUTH_URL}/auth/account`,
    account_iban: bankDetails.iban,
    account_bic: bankDetails.bic,
  };

  return await fetchPostJSON(
    `/api/psp/merchants/${merchantId}/bankAccounts`,
    null,
    bankAccountData
  );
};

const createFormData = (
  documentType,
  originFileObj,
  merchantId,
  objectUid,
  purposeSuffix
) => {
  const formData = new FormData();
  formData.append(
    'purpose',
    `representative_${documentType}${purposeSuffix ? `_${purposeSuffix}` : ''}`
  );
  formData.append('merchant_uid', merchantId);
  formData.append('object_uid', objectUid);
  formData.append('file', originFileObj);
  return formData;
};

interface KycFormData {
  documentType: 'passport' | 'id_card';
  passport?: {
    [key: number]: {
      originFileObj: File;
    };
  };
  id_card?: {
    front: [
      {
        originFileObj: File;
      },
    ];
    backside: [
      {
        originFileObj: File;
      },
    ];
  };
}

interface UploadKycDocumentsParams {
  form: FormInstance;
  merchantId: string;
}

interface UploadResponse {
  success: boolean;
  message?: string;
  data?: unknown;
  error?: string;
}

/**
 * Uploads KYC documents for merchant verification
 * @param params - Object containing form instance and merchant ID
 * @returns Promise resolving to array of upload responses or error message
 * @throws Error if document upload fails
 */
export const uploadKycDocuments = async ({
  form,
  merchantId,
}: UploadKycDocumentsParams): Promise<UploadResponse[]> => {
  try {
    const kycFormData = form.getFieldValue('kyc') as KycFormData;
    const merchant: Merchant = await fetchGetJSON(
      `/api/psp/merchants/${merchantId}?expand[]=contacts`
    );

    if (!merchant?.contacts?.length) {
      return Promise.reject([
        { success: false, error: 'No merchant contacts found' },
      ]);
    }

    if (['pending', 'verified'].includes(merchant.contacts[0].status)) {
      return Promise.resolve([
        { success: false, error: 'KYC already in review or complete' },
      ]);
    }

    const promises: FormData[] =
      kycFormData.documentType === 'passport'
        ? [
            createFormData(
              kycFormData.documentType,
              kycFormData.passport?.[0].originFileObj,
              merchantId,
              merchant.contacts[0].uid,
              null
            ),
          ]
        : ['front', 'backside'].map((side) =>
            createFormData(
              kycFormData.documentType,
              kycFormData[kycFormData.documentType]?.[side][0].originFileObj,
              merchantId,
              merchant.contacts[0].uid,
              side
            )
          );

    const responses = await Promise.all(
      promises.map(async (formData): Promise<UploadResponse> => {
        const response = await fetch('/api/psp/uploads', {
          method: 'POST',
          body: formData,
        });
        return response.json();
      })
    );

    return responses;
  } catch (error) {
    console.error('Error uploading documents:', error);
    throw new Error(
      error instanceof Error ? error.message : 'Failed to upload KYC documents'
    );
  }
};

interface CreateMandateAndTransactionProps {
  userData: UserProps;
  form: FormInstance;
  referral: ReferralProps;
  returnUrl: string;
  save: any;
  paymentSuccess(
    success: boolean,
    error?: any,
    paymentDetails?: TransactionPaymentDetails
  ): void;
  mockPayment: string;
}

export const createMandateAndTransaction = async ({
  userData,
  form,
  referral,
  returnUrl,
  save,
  paymentSuccess,
  mockPayment,
}: CreateMandateAndTransactionProps) => {
  try {
    // const bankDetails = form.getFieldValue('bankDetails');

    const products = [
      {
        name: 'Annual Membership',
        quantity: 1,
        price: formatAmountForOPP(
          referral ? PITCHEDIT_FEES - referral.payout : PITCHEDIT_FEES
        ),
      },
      {
        name: 'Quarterly Investment',
        quantity: 1,
        price: formatAmountForOPP(
          userData.investorDetails.quarterlySubscription
        ),
      },
    ];

    // const mandateData = buildMandate({
    //   merchantId: userData.OPP.merchantId,
    //   paymentMethod,
    //   bankDetails,
    //   accountDetails: {
    //     firstName: userData.accountDetails.firstName,
    //     lastName: userData.accountDetails.lastName,
    //   },
    //   products,
    //   total: referral ? PITCHEDIT_FEES - referral.payout : PITCHEDIT_FEES,
    //   userId: userData.id,
    //   returnUrl,
    // });

    // const mandate = await fetchPostJSON(`/api/psp/mandates`, null, mandateData);

    // if (mandate.error || mandate.status !== 'completed') {
    //   return paymentSuccess(false, mandate.error);
    // }

    // const transactionData: MandateTransactionInput = {
    //   total_price:
    //     process.env.ACTION_ENVIRONMENT !== 'production' && !!Number(mockPayment)
    //       ? Number(mockPayment)
    //       : formatAmountForOPP(
    //           referral ? PITCHEDIT_FEES - referral.payout : PITCHEDIT_FEES
    //         ),
    //   token: mandate.token,
    //   reference: `type:Annual Membership|user:${userData.id}|date:${Date.now()}`,
    //   notify_url: `${process.env.NEXTAUTH_URL}/api/psp/transactions/webhooks`,
    //   metadata: {
    //     user: userData.id.toString(),
    //     type: 'Annual Membership',
    //   },
    //   merchant_uid: userData.OPP.merchantId,
    //   partner_fee: formatAmountForOPP(
    //     referral ? PITCHEDIT_FEES - referral.payout : PITCHEDIT_FEES
    //   ),
    //   products,
    // };

    const isMocked =
      process.env.ACTION_ENVIRONMENT !== 'production' && !!Number(mockPayment);
    const totalPrice = isMocked
      ? Number(mockPayment)
      : formatAmountForOPP(
          referral ? PITCHEDIT_FEES - referral.payout : PITCHEDIT_FEES
        );
    const partnerFee = isMocked
      ? 100
      : formatAmountForOPP(
          referral ? PITCHEDIT_FEES - referral.payout : PITCHEDIT_FEES
        );

    const transactionData: TransactionInput = {
      total_price: totalPrice,
      notify_url: `${process.env.NEXTAUTH_URL}/api/psp/transactions/webhooks`,
      return_url: returnUrl,
      checkout: false,
      payment_method: 'sepa',
      metadata: {
        user: userData.id,
        type: 'Annual Membership Quarterly Investment',
      },
      merchant_uid: userData.OPP.merchantId,
      partner_fee: partnerFee,
      products,
      date_expired: moment().add(1, 'year').subtract(1, 'day').toISOString(),
    };

    const transaction: Transaction = await fetchPostJSON(
      `/api/psp/transactions`,
      null,
      transactionData
    );

    if (transaction.error) return paymentSuccess(false, transaction.error);

    // const truncatedFirstName = userData.accountDetails.firstName.slice(0, 3);
    // const truncatedLastName = userData.accountDetails.lastName.slice(0, 3);
    // const truncatedUserId = userData.id.toString().slice(0, 4);
    // const code =
    //   `${truncatedFirstName}${truncatedLastName}${truncatedUserId}`.toUpperCase();

    // const paymentReferenceData: PaymentReferenceInput = {
    //   merchant_uid: userData.OPP.merchantId,
    //   notify_url: `${process.env.NEXTAUTH_URL}/api/psp/paymentReferences/webhooks`,
    //   code,
    // };

    // const paymentReference: PaymentReference = await fetchPostJSON(
    //   `/api/psp/paymentReferences`,
    //   null,
    //   paymentReferenceData
    // );

    await save({
      variables: {
        OPP: {
          id: userData.OPP.id,
          // mandateId: mandate.uid,
          annualFee: {
            transactionId: transaction.uid,
            status: transaction.status,
          },
          paymentInstructions: {
            accountName: transaction.payment_details.provider_bank_account_name,
            iban: transaction.payment_details.provider_bank_account_iban,
            bic: transaction.payment_details.provider_bank_account_bic,
            reference: transaction.payment_details.reference,
            expired: transaction.payment_details.expired.toString(),
          },
        },
        investorDetails: {
          id: userData.investorDetails.id,
          transactionId: transaction.uid,
          transactionStatus: transaction.status,
        },
      },
    });

    paymentSuccess(true, null, transaction.payment_details);
  } catch (error) {
    return paymentSuccess(false, error);
  }
};

interface CreateRenewalTransactionProps {
  userData: UserProps;
  paymentType: 'annual' | 'quarterly' | 'annualQuarterly';
  returnUrl: string;
  save: MutationFunction;
  paymentSuccess: (success: boolean, error?: any) => void;
  referral: ReferralProps;
  mockPayment: string;
}

export const createRenewalTransaction = async ({
  userData,
  paymentType,
  returnUrl,
  save,
  paymentSuccess,
  referral,
  mockPayment,
}: CreateRenewalTransactionProps) => {
  try {
    const products = [];

    const annualProduct = {
      name: 'Annual Membership',
      quantity: 1,
      price: formatAmountForOPP(PITCHEDIT_FEES),
    };
    const quarterlyProduct = {
      name: 'Quarterly Investment',
      quantity: 1,
      price: formatAmountForOPP(userData.investorDetails.quarterlySubscription),
    };

    if (paymentType === 'annual') {
      products.push(annualProduct);
    }
    if (paymentType === 'quarterly') {
      products.push(quarterlyProduct);
    }
    if (paymentType === 'annualQuarterly') {
      products.push(annualProduct, quarterlyProduct);
    }

    const isMocked =
      (process.env.ACTION_ENVIRONMENT !== 'production' ||
        userData.role.name === 'Super Admin') &&
      !!Number(mockPayment);
    const totalPrice = isMocked
      ? Number(mockPayment)
      : formatAmountForOPP(
          calculateTotalPrice(paymentType, userData, referral)
        );
    const partnerFee =
      paymentType === 'quarterly'
        ? 0
        : isMocked || userData.role.name === 'Super Admin'
          ? 100
          : formatAmountForOPP(
              referral ? PITCHEDIT_FEES - referral.payout : PITCHEDIT_FEES
            );

    const transactionData: TransactionInput = {
      total_price: totalPrice,
      notify_url: `${process.env.NEXTAUTH_URL}/api/psp/transactions/webhooks`,
      return_url: returnUrl,
      checkout: false,
      payment_method: 'sepa',
      metadata: {
        user: userData.id,
        type:
          paymentType === 'annual'
            ? 'Annual Membership'
            : paymentType === 'quarterly'
              ? 'Quarterly Investment'
              : 'Annual Membership Quarterly Investment',
      },
      merchant_uid: userData.OPP.merchantId,
      partner_fee: partnerFee,
      date_expired: moment().add(1, 'year').subtract(1, 'day').toISOString(),
      products,
    };

    const transaction: Transaction = await fetchPostJSON(
      `/api/psp/transactions`,
      null,
      transactionData
    );

    if (transaction.error) return paymentSuccess(false, transaction.error);

    let updateData = {};

    if (paymentType === 'annual') {
      updateData = {
        OPP: {
          id: userData.OPP.id,
          annualFee: {
            id: userData.OPP.annualFee?.id,
            transactionId: transaction.uid,
            status: transaction.status,
          },
          paymentInstructions: {
            accountName: transaction.payment_details.provider_bank_account_name,
            iban: transaction.payment_details.provider_bank_account_iban,
            bic: transaction.payment_details.provider_bank_account_bic,
            reference: transaction.payment_details.reference,
            expired: transaction.payment_details.expired.toString(),
          },
        },
      };
    }

    if (paymentType === 'quarterly') {
      updateData = {
        OPP: {
          id: userData.OPP.id,
          paymentInstructions: {
            accountName: transaction.payment_details.provider_bank_account_name,
            iban: transaction.payment_details.provider_bank_account_iban,
            bic: transaction.payment_details.provider_bank_account_bic,
            reference: transaction.payment_details.reference,
            expired: transaction.payment_details.expired.toString(),
          },
        },
        investorDetails: {
          id: userData.investorDetails?.id,
          transactionId: transaction.uid,
          transactionStatus: transaction.status,
        },
      };
    }

    if (paymentType === 'annualQuarterly') {
      updateData = {
        OPP: {
          id: userData.OPP.id,
          annualFee: {
            id: userData.OPP.annualFee?.id,
            transactionId: transaction.uid,
            status: transaction.status,
          },
          paymentInstructions: {
            accountName: transaction.payment_details.provider_bank_account_name,
            iban: transaction.payment_details.provider_bank_account_iban,
            bic: transaction.payment_details.provider_bank_account_bic,
            reference: transaction.payment_details.reference,
            expired: transaction.payment_details.expired.toString(),
          },
        },
        investorDetails: {
          id: userData.investorDetails?.id,
          transactionId: transaction.uid,
          transactionStatus: transaction.status,
        },
      };
    }

    await save({
      variables: {
        ...updateData,
      },
    });

    paymentSuccess(true);
  } catch (error) {
    return paymentSuccess(false, error);
  }
};

interface CreateManualDepositTransactionProps {
  amount: number;
  userData: UserProps;
  returnUrl: string;
  save: MutationFunction;
  paymentSuccess: (success: boolean, error?: any) => void;
  mockPayment: string;
}

export const createManualDepositTransaction = async ({
  amount,
  userData,
  returnUrl,
  save,
  paymentSuccess,
  mockPayment,
}: CreateManualDepositTransactionProps) => {
  try {
    const isMocked =
      (process.env.ACTION_ENVIRONMENT !== 'production' ||
        userData.role.name === 'Super Admin') &&
      !!Number(mockPayment);
    const totalPrice = isMocked
      ? Number(mockPayment)
      : formatAmountForOPP(amount);

    const transactionData: TransactionInput = {
      total_price: totalPrice,
      notify_url: `${process.env.NEXTAUTH_URL}/api/psp/transactions/webhooks`,
      return_url: returnUrl,
      checkout: false,
      payment_method: 'sepa',
      metadata: {
        user: userData.id,
        type: 'Manual Deposit',
      },
      merchant_uid: userData.OPP.merchantId,
      partner_fee: 0,
      date_expired: moment().add(1, 'year').subtract(1, 'day').toISOString(),
      products: [
        {
          name: 'Manual Deposit',
          quantity: 1,
          price: formatAmountForOPP(amount),
        },
      ],
    };

    const transaction: Transaction = await fetchPostJSON(
      `/api/psp/transactions`,
      null,
      transactionData
    );

    if (transaction.error) return paymentSuccess(false, transaction.error);

    const updateData = {
      OPP: {
        id: userData.OPP.id,
        manualPayment: {
          id: userData.OPP.manualPayment?.id,
          transactionId: transaction.uid,
          status: transaction.status,
          accountName: transaction.payment_details.provider_bank_account_name,
          iban: transaction.payment_details.provider_bank_account_iban,
          bic: transaction.payment_details.provider_bank_account_bic,
          reference: transaction.payment_details.reference,
          expired: transaction.payment_details.expired.toString(),
        },
      },
    };

    await save({
      variables: {
        ...updateData,
      },
    });

    return paymentSuccess(true);
  } catch (error) {
    return paymentSuccess(false, error);
  }
};

/**
 * Calculates the annual membership fee taking into account any referral discounts
 * @param referral - Optional referral data containing payout amount
 * @returns The final annual membership fee
 */
export const calculateAnnualFee = (referral?: ReferralProps): number => {
  return referral ? PITCHEDIT_FEES - referral.payout : PITCHEDIT_FEES;
};

/**
 * Calculates the total price based on payment type and user data
 * @param paymentType - Type of payment ('quarterly', 'annual', or 'annualQuarterly')
 * @param userData - User data containing investor details
 * @param referral - Optional referral data
 * @returns Formatted amount for payment processing
 */
export const calculateTotalPrice = (
  paymentType: 'quarterly' | 'annual' | 'annualQuarterly',
  userData: UserProps,
  referral?: ReferralProps
): number => {
  const quarterlyAmount = userData.investorDetails.quarterlySubscription;
  const annualAmount =
    userData.role.name === 'Super Admin' ? 1 : calculateAnnualFee(referral);

  switch (paymentType) {
    case 'quarterly':
      return quarterlyAmount;
    case 'annual':
      return annualAmount;
    case 'annualQuarterly':
      return annualAmount + quarterlyAmount;
    default:
      return 0;
  }
};
